<template>
  <div>
    <TableLeads :leads="leads" @search="search"/>
  </div>
</template>

<script>
export default {
  components: {
    TableLeads: () => import("./components/TableLeads"),
  },
  computed: {
    leads() {
      return this.$store.state.leads.leads;
    },
  },
  methods:{
      async search(v){
          console.log(v)
          await this.$store.dispatch('leads/get_filtered_leads', v)          
      }
  },
  mounted() {
    this.$store.dispatch("leads/leads_init");
  },
};
</script>

<style>
</style>